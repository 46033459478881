import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Pediatric2Page: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const product1 = data.product1?.childImageSharp?.fluid
  const father_and_daughter_brushing_teeth_in_bathroom = data.father_and_daughter_brushing_teeth_in_bathroom?.childImageSharp?.fluid
  const close_up_of_woman_using_dental_floss_from_pack_in_bathroom =data.close_up_of_woman_using_dental_floss_from_pack_in_bathroom?.childImageSharp?.fluid


  return (
    <Layout location={location}>
      <Meta site={meta} title="Pediatric Dentistry" />
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
              PEDIATRIC DENTAL SERVICES MCCORDSVILLE
              </p>
              <h1 className="text-light">
              Pediatric Dentistry
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer many types of pediatric dentistry procedures to help your kids achieve the perfect smile. Browse the page below to learn more. It’s easy to schedule your appointment with us. </p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <button className="btn-rounded"> Learn More </button>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
          <div className="col-lg-6">
              <div className="col-md-6">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-2-header">Children's Smiles Are Important!</h3>
                <p>Your children’s teeth are precious. They need to be taken care of in order to last them a life time. Dr. Vogt can help you to teach your kids to take great care of their teeth. We know how important your kids are to you and that is why we offer a full line of pediatric dental services as well as a kid friendly environment. We want you and your kids to enjoy every trip to the dentist’s office.</p>
              </div>
              <div className="col-md-6">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Happier Kids</h3>
                <p>Your kids won't mind going to the dentist office when they come to see Dr. Vogt. They will leave happy and be happy to come back.</p>
              </div>
                <div className="col-md-6">
                  <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                  <h3 className ="section-1-header">Less Medical Expenses</h3>
                  <p>A healthy mouth is a sign of a health body. When your children have better health, you save money.</p>
                </div>
            </div>
            <div className="col-lg-6">
              <Img fluid = {father_and_daughter_brushing_teeth_in_bathroom} />
            </div>
          </Container>
          <Container>
            <div className =" col-lg-12"><p></p></div>
          </Container>
          <Container>
          <div className="col-lg-6">
            <Img fluid = {close_up_of_woman_using_dental_floss_from_pack_in_bathroom} />
          </div>
          <div className = "col-lg-6">
            <div>
              <i className="fa fa-bolt" aria-hidden="true"></i>
              <h3 className ="section-1-header">Cuter Pictures</h3>
              <p>We know you love your kids and your phone is probably full of their phots. When you make sure your kids teeth are health you're guaranteed better pictures.</p>
            </div>
            <div>
              <i className="fa fa-diamond" aria-hidden="true"></i>
              <h3 className ="section-1-header">Gain Valuable Insight</h3>
              <p>Every trip to our office is more than just a dental appointment. We teach you and your kids about oral health and the best preventative care techniques.</p>
            </div>
            <div>
              <i className="fa fa-bookmark-o" aria-hidden="true"></i>
              <h3 className ="section-1-header">Better Child Development</h3>
              <p>The healthy appearance of your child can play a large role in their psychosocial development. Basically, if they have healthy teeth, they will be accepted more readily by their peers.</p>
            </div>
          </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="the dentists at crossroad"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                KEEP YOUR KIDS HEALTHY!
                </p>
                <h3 className="text-light">
                Don't miss your chance to give your kids the very best teeth!
                </h3>
                <p className="hero-text text-light">
                Your children NEED healthy teeth. Don’t waste another day letting them go without proper dental care. Children shouldn’t have to deal with  poor oral health if they can avoid it. We have affordable  payment options. Call TODAY!
                </p>
                <a href="tel:3176439434" className="text-left"><button className="btn-rounded"><i className="fa fa-phone"></i> (317) 643-9434 </button></a>
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">PEDIATRIC DENTAL DETAILS</p>
              <h2 className ="section-3-header">What is Pediatric Dentistry ?</h2>
             <p>Pediatric Dentistry is a field of dentistry that is focused on children. These little humans only grow up once and it is important to give them the right habits and information as early as possible. It’s a proven fact that people that have healthy teeth and gums live longer happier lives. We know you want the best for your kids and so do we. That’s why we have made your kids our number one priority. </p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is A Children's Dentist Right For Your Child?</h3>
                <p>Do you have kids? Do they have teeth? Then we say yes! It takes a special type of dentist to work with kids successfully. Dr. Vogt is gentle and patient and knows how to communicate with children. He aims to provide the best dental care while making the experience fun and easy for everyone.</p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Our Pediatric Dentistry Services</h3>
                <p>Our Pediatric dentistry services include:</p>
                <ul>
                  <li><Link to="/services/preventive-dentistry">Preventive Dentistry</Link></li>
                  <li><Link to= "/services/restorative-dentistry">Restorative Dentistry</Link></li>
                  <li><Link to= "/services/oral-surgery">Oral Surgery</Link></li>
                  <li><Link to= "/services/cosmetic-dentistry">Cosmetic Dentistry</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are My Child's Pediatric Dental Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <a href="tel:3176439434">317-643-9434</a>.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default Pediatric2Page

export const query = graphql`
  query Pediatric2PageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    product1: file(relativePath: { eq: "images/marco-pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    father_and_daughter_brushing_teeth_in_bathroom: file(name: { eq: "cute-girl-writing-on-notebook-smiling-at-camera" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    close_up_of_woman_using_dental_floss_from_pack_in_bathroom: file(name: { eq: "smiling-boys-and-girls-lying-in-a-pile-on-grass-in-summer" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
